import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import apiService from "../../services/ApiService";
import { toast } from "react-hot-toast";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function AttendanceLogs() {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [noRecords, setNoRecords] = useState(false);
  const [filter, setFilter] = useState("day");

  useEffect(() => {
    const fetchAttendanceLogs = async () => {
      try {
        const response = await apiService.get(`/api/v1/users/attendanceLogs?filter=${filter}`);
        if (response.status === 200) {
          const logs = response.data.data;

          if (logs.length === 0) {
            setNoRecords(true);
            setRows([]); // Clear rows when no records
            return;
          }

          // Set the columns for the attendance logs table
          setColumns([
            { Header: "User Name", accessor: "userName", width: "20%", align: "left" },
            { Header: "Email", accessor: "email", width: "20%", align: "left" },
            { Header: "Date", accessor: "date", width: "15%", align: "center" },
            { Header: "Check In Time", accessor: "checkInTime", width: "15%", align: "center" },
            { Header: "Check Out Time", accessor: "checkOutTime", width: "15%", align: "center" },
          ]);

          // Flatten the attendance logs
          const formattedRows = logs.flatMap((log) =>
            log.attendance.map((attendance) => ({
              userName: log.user.name,
              email: log.user.email,
              date: attendance.date || "N/A",
              checkInTime: attendance.checkInTime || "N/A",
              checkOutTime: attendance.checkOutTime || "N/A",
            }))
          );

          setRows(formattedRows);
          setNoRecords(false); // Reset noRecords if there are logs
        }
      } catch (error) {
        toast.error("Failed to fetch attendance logs");
      }
    };

    fetchAttendanceLogs();
  }, [filter]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Attendance Logs
                </MDTypography>
              </MDBox>
              <MDBox pt={3} px={2}>
                <Select
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  fullWidth
                >
                  <MenuItem value="day">Last 1 Day</MenuItem>
                  <MenuItem value="week">Last 7 Days</MenuItem>
                  <MenuItem value="month">Last 30 Days</MenuItem>
                </Select>
              </MDBox>
              <MDBox pt={3}>
                {noRecords ? (
                  <MDTypography variant="h6" color="text" align="center" my={2}>
                    No attendance logs found.
                  </MDTypography>
                ) : (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AttendanceLogs;

import React, { useEffect, useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import Header from "layouts/profile/components/Header";
import apiService from "../../services/ApiService";
import { AuthContext } from "../../context/AuthContext";

function Overview() {
  // const [profiles, setProfiles] = useState([]);
  const { user, permission } = useContext(AuthContext);
  const { Admin = false, HrManager = false, HRBP = false } = permission;
  // console.log(profiles);
  // useEffect(() => {
  //   const fetchPendingUsers = async () => {
  //     const departmentId = user?.department?._id;
  //     const userId = user?._id;
  //     if (!departmentId || !userId) return; // Exit if departmentId or userId is not available
  //     try {
  //       const response = await apiService.post("/api/v1/users/pendingUsersList", {
  //         departmentId,
  //         userId,
  //       });

  //       setProfiles(response.data.users); // Assuming the API response contains a 'users' array
  //     } catch (err) {
  //       console.error("Error fetching pending users:", err);
  //     }
  //   };

  //   fetchPendingUsers();
  // }, [user]); // Dependency array - run this effect when 'user' changes

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header firstName={user?.firstName} secondName={user?.secondName}>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={5} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="profile information"
                info={{
                  firstName: user?.firstName,
                  lastName: user?.lastName,
                  employeeNo: user?.employeeNo,
                  email: user?.email,
                  createdAt: new Date(user?.createdAt).toLocaleDateString(),
                }}
                action={{ route: `/users/edit/${user?._id}`, tooltip: "Edit Profile" }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>

            {/* Conditionally render the Pending Approvals section */}
            {/* {(Admin || HrManager || HRBP) && ( */}
            {/* <Grid item xs={12} xl={4}> */}
            {/* <ProfilesList title="Pending Approvals" profiles={profiles} shadow={false} /> */}
            {/* </Grid> */}
            {/* )} */}
          </Grid>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Companies from "layouts/companies";
import BusinessUnits from "layouts/business";
import Departments from "layouts/departments";
import Profile from "layouts/profile";
import Users from "layouts/users";
import Roles from "layouts/roles";
import Pendings from "layouts/pendings";
import Attendances from "layouts/attendances";
import MarkAttendance from "layouts/markAttendance";

// @mui icons
import Icon from "@mui/material/Icon";
import { AuthContext } from "context/AuthContext";
import { useContext } from "react";

const UseRoutes = () => {
  const { permission } = useContext(AuthContext);
  const {
    Admin = false,
    HrManager = false,
    HRBP = false,
    ItAdmin = false,
    Employee = false,
  } = permission;

  console.log("permission", permission);

  // Define all routes and their required permissions
  const routes = [
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: "/dashboard",
      component: <Dashboard />,
      permission: Admin || HrManager || Employee,
    },
    {
      type: "collapse",
      name: "Companies",
      key: "companies",
      icon: <Icon fontSize="small">business</Icon>,
      route: "/companies",
      component: <Companies />,
      permission: Admin,
    },
    {
      type: "collapse",
      name: "Business Units",
      key: "business-units",
      icon: <Icon fontSize="small">apartment</Icon>,
      route: "/business-units",
      component: <BusinessUnits />,
      permission: Admin,
    },
    {
      type: "collapse",
      name: "Departments",
      key: "departments",
      icon: <Icon fontSize="small">account_tree</Icon>,
      route: "/departments",
      component: <Departments />,
      permission: Admin,
    },
    {
      type: "collapse",
      name: "Users",
      key: "users",
      icon: <Icon fontSize="small">people</Icon>,
      route: "/users",
      component: <Users />,
      permission: Admin,
    },
    {
      type: "collapse",
      name: "Pending Users",
      key: "pendings",
      icon: <Icon fontSize="small">people</Icon>,
      route: "/pendings",
      component: <Pendings />,
      permission: HrManager || HRBP,
    },
    {
      type: "collapse",
      name: "Attendance Logs",
      key: "attendances",
      icon: <Icon fontSize="small">schedule</Icon>,
      route: "/attendances",
      component: <Attendances />,
      permission: Admin,
    },
    {
      type: "collapse",
      name: "Mark Attendance",
      key: "markAttendances",
      icon: <Icon fontSize="small">schedule</Icon>,
      route: "/markAttendances",
      component: <MarkAttendance />,
      permission: Employee,
    },
    {
      type: "collapse",
      name: "Roles",
      key: "roles",
      icon: <Icon fontSize="small">supervised_user_circle</Icon>,
      route: "/roles",
      component: <Roles />,
      permission: Admin,
    },
    {
      type: "collapse",
      name: "Profile",
      key: "profile",
      icon: <Icon fontSize="small">person</Icon>,
      route: "/profile",
      component: <Profile />,
      permission: Admin || HrManager || Employee,
    },
  ];

  // Filter routes based on the user’s permissions
  const allowedRoutes = routes.filter((route) => route.permission);

  return allowedRoutes;
};

export default UseRoutes;

import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate, useParams } from "react-router-dom";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import apiService from "../../services/ApiService";

// Set default center for map
const defaultCenter = { lat: 29.336251146109127, lng: 47.93002145996093 };

function Edit() {
  const { id } = useParams();
  const navigate = useNavigate();

  // State for form data
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    code: "",
    businessUnits: [],
    radius: "",
    location: { lat: defaultCenter.lat, lng: defaultCenter.lng },
  });
  const [businessUnitOptions, setBusinessUnitOptions] = useState([]);
  const [mapCenter, setMapCenter] = useState(defaultCenter);

  // Load Google Maps
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDctfNdridCgXmF6tQw4ef1tkcteV7VT7A", // Replace with your API key
  });

  // Fetch data when the component mounts
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        // Fetch business unit options
        const response = await apiService.get("/api/v1/businessUnits/list");
        if (response.status === 200) {
          setBusinessUnitOptions(
            response.data.businessUnits.map((unit) => ({
              value: unit._id,
              label: unit.name,
            }))
          );
        }

        // Fetch the department data by ID
        const departmentResponse = await apiService.get(`/api/v1/departments/detail/${id}`);
        if (departmentResponse.status === 200) {
          const department = departmentResponse.data.department;
          setFormData({
            name: department.name,
            description: department.description,
            code: department.code,
            businessUnits: department.businessUnits.map((bu) => bu.businessUnit),
            radius: department.radius,
            location: department.location || { lat: defaultCenter.lat, lng: defaultCenter.lng },
          });
          setMapCenter(department.location || defaultCenter);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchOptions();
  }, [id]);

  // Handle input change for text fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle business unit selection
  const handleBusinessUnitChange = (event) => {
    setFormData({
      ...formData,
      businessUnits: event.target.value,
    });
  };

  // Handle marker drag and update location
  const handleMarkerDragEnd = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setFormData({
      ...formData,
      location: { lat, lng },
    });
    setMapCenter({ lat, lng });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await apiService.put(`/api/v1/departments/update/${id}`, {
        name: formData.name,
        description: formData.description,
        code: formData.code,
        businessUnits: formData.businessUnits,
        location: formData.location, // Send updated location data
        radius: formData.radius, // Ensure radius is sent
      });

      if (response.status === 200) {
        navigate("/departments");
        console.log("Department updated successfully");
      }
    } catch (error) {
      console.error("Failed to update department:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Edit Department
                </MDTypography>
              </MDBox>
              <MDBox pt={3} px={2} component="form" onSubmit={handleSubmit}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Department Name"
                    name="name"
                    variant="standard"
                    fullWidth
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Description"
                    name="description"
                    variant="standard"
                    fullWidth
                    value={formData.description}
                    onChange={handleInputChange}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Code"
                    name="code"
                    variant="standard"
                    fullWidth
                    value={formData.code}
                    onChange={handleInputChange}
                  />
                </MDBox>

                <MDBox mb={2}>
                  <MDInput
                    type="number"
                    label="Radius (meters)"
                    name="radius"
                    variant="standard"
                    fullWidth
                    value={formData.radius}
                    onChange={handleInputChange}
                  />
                </MDBox>

                <MDBox mb={2}>
                  <FormControl fullWidth>
                    <InputLabel id="business-units-label">Select Business Units</InputLabel>
                    <Select
                      labelId="business-units-label"
                      multiple
                      variant="standard"
                      value={formData.businessUnits}
                      onChange={handleBusinessUnitChange}
                      renderValue={(selected) =>
                        selected
                          .map(
                            (id) => businessUnitOptions.find((option) => option.value === id)?.label
                          )
                          .join(", ")
                      }
                    >
                      {businessUnitOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          <Checkbox checked={formData.businessUnits.includes(option.value)} />
                          <ListItemText primary={option.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MDBox>
                <MDBox mb={2}>
                  <MDTypography variant="h6">Update Location</MDTypography>
                  {isLoaded && (
                    <GoogleMap
                      center={mapCenter}
                      zoom={15}
                      mapContainerStyle={{ height: "400px", width: "100%" }}
                    >
                      <Marker
                        position={formData.location}
                        draggable
                        onDragEnd={handleMarkerDragEnd}
                      />
                    </GoogleMap>
                  )}
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton variant="gradient" color="info" type="submit">
                    Save Changes
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Edit;

import React, { useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import apiService from "services/ApiService";
import { AuthContext } from "context/AuthContext";

function MarkAttendance() {
  const { user } = useContext(AuthContext);

  // State to manage attendance status and message display
  const [attendanceStatus, setAttendanceStatus] = useState({
    checkIn: false,
    checkInTime: null,
    checkOut: false,
    checkOutTime: null,
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  // Helper function to get the user's current location (geolocation)
  const getCurrentLocation = () =>
    new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            resolve({ lat: latitude, lng: longitude });
          },
          (error) => {
            reject(error.message);
          }
        );
      } else {
        reject("Geolocation is not supported by this browser.");
      }
    });

  const handleAttendance = async (status) => {
    if (!user?.email) {
      setMessage("User email not found. Please log in.");
      return;
    }

    setLoading(true);
    setMessage("");

    try {
      // Get the user's current location
      const currentLocation = await getCurrentLocation();

      // Make API call to mark attendance
      const response = await apiService.post("/api/v1/users/markAttendance", {
        email: user.email, // Use the logged-in user's email
        status: status,
        currentLocation, // Send the user's current location
      });

      // Update the attendance status based on response
      if (response.data.success) {
        setAttendanceStatus({
          checkIn: response.data.attendanceStatus.checkIn,
          checkInTime: response.data.attendanceStatus.checkInTime,
          checkOut: response.data.attendanceStatus.checkOut,
          checkOutTime: response.data.attendanceStatus.checkOutTime,
        });
        setMessage(response.data.message);
      } else {
        // Handle cases where success is false but no error was thrown
        setMessage(response.data.message || "Failed to mark attendance.");
      }
    } catch (error) {
      // Handle API errors and display appropriate messages
      if (error.response && error.response.data.message) {
        setMessage(error.response.data.message);
      } else {
        setMessage("An error occurred while marking attendance.");
      }
    }

    setLoading(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Mark Your Attendance
                </MDTypography>
              </MDBox>

              <MDBox pt={3} px={3}>
                {message && (
                  <MDTypography variant="h6" color="error" mb={2}>
                    {message}
                  </MDTypography>
                )}

                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => handleAttendance("checkIn")}
                      disabled={attendanceStatus.checkIn || loading}
                      fullWidth
                    >
                      {loading && attendanceStatus.checkIn === false
                        ? "Checking In..."
                        : "Check In"}
                    </Button>
                  </Grid>

                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={() => handleAttendance("checkOut")}
                      disabled={!attendanceStatus.checkIn || attendanceStatus.checkOut || loading}
                      fullWidth
                    >
                      {loading &&
                      attendanceStatus.checkIn === true &&
                      attendanceStatus.checkOut === false
                        ? "Checking Out..."
                        : "Check Out"}
                    </Button>
                  </Grid>
                </Grid>

                <MDBox mt={3}>
                  <MDTypography variant="body1">
                    <strong>Check-In Time:</strong>{" "}
                    {attendanceStatus.checkInTime
                      ? attendanceStatus.checkInTime
                      : "Not Checked In Yet"}
                  </MDTypography>
                  <MDTypography variant="body1" mt={1}>
                    <strong>Check-Out Time:</strong>{" "}
                    {attendanceStatus.checkOutTime
                      ? attendanceStatus.checkOutTime
                      : "Not Checked Out Yet"}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default MarkAttendance;

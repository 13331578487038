import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/background.jpg";
import apiService from "../../../services/ApiService";

function Cover() {
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    employeeNo: "",
    company: "",
    businessUnit: "",
    department: "",
    password: "",
    role: "Employee",
  });

  const [companies, setCompanies] = useState([]);
  const [allBusinessUnits, setAllBusinessUnits] = useState([]);
  const [filteredBusinessUnits, setFilteredBusinessUnits] = useState([]);
  const [allDepartments, setAllDepartments] = useState([]);
  const [filteredDepartments, setFilteredDepartments] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const companiesResponse = await apiService.get("/api/v1/companies/list");
        const businessUnitsResponse = await apiService.get("/api/v1/businessUnits/list");
        const departmentsResponse = await apiService.get("/api/v1/departments/list");

        setCompanies(companiesResponse.data.companies);
        setAllBusinessUnits(businessUnitsResponse.data.businessUnits);
        setAllDepartments(departmentsResponse.data.departments);
      } catch (error) {
        console.error("Error fetching dropdown data", error);
        toast.error("Error fetching dropdown data");
      }
    };

    fetchDropdownData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "company") {
      // Filter business units based on selected company
      const filteredBU = allBusinessUnits.filter(
        (businessUnit) => businessUnit.company._id === value
      );

      // Reset businessUnit and department when a new company is selected
      setUser((prevUser) => ({
        ...prevUser,
        company: value,
        businessUnit: "",
        department: "",
      }));

      setFilteredBusinessUnits(filteredBU);
      setFilteredDepartments([]); // Reset Departments dropdown
    } else if (name === "businessUnit") {
      // Filter departments based on selected business unit and active status
      const filteredDept = allDepartments.filter((department) =>
        department.businessUnits.some((bu) => bu.businessUnit._id === value && bu.status === true)
      );

      // Reset the department when a new business unit is selected
      setUser((prevUser) => ({
        ...prevUser,
        businessUnit: value,
        department: "",
      }));

      setFilteredDepartments(filteredDept);
    } else if (name === "department") {
      // Update the state when a department is selected
      setUser((prevUser) => ({
        ...prevUser,
        department: value,
      }));
    } else {
      // For other fields, just update the state
      setUser((prevUser) => ({
        ...prevUser,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await apiService.post("/api/v1/users/signUp", user);
      if (response.status === 201) {
        toast.success("User Successfully Signed Up");
        navigate("/signIn");
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Something went wrong";
      toast.error(errorMessage);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-2}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us Today
          </MDTypography>

          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter Your Personal Details To Register
          </MDTypography>
        </MDBox>
        <MDBox pt={2} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="First Name"
                    name="firstName"
                    value={user.firstName}
                    onChange={handleChange}
                    variant="standard"
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Last Name"
                    name="lastName"
                    value={user.lastName}
                    onChange={handleChange}
                    variant="standard"
                    fullWidth
                  />
                </MDBox>
              </Grid>
            </Grid>

            <FormControl fullWidth variant="standard" margin="normal">
              <InputLabel>Company</InputLabel>
              <Select name="company" value={user.company} onChange={handleChange}>
                {companies.map((company) => (
                  <MenuItem key={company._id} value={company._id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              fullWidth
              variant="standard"
              margin="normal"
              disabled={!filteredBusinessUnits.length}
            >
              <InputLabel>Business Unit</InputLabel>
              <Select
                name="businessUnit"
                value={user.businessUnit}
                onChange={handleChange}
                disabled={!filteredBusinessUnits.length}
              >
                {filteredBusinessUnits.map((businessUnit) => (
                  <MenuItem key={businessUnit._id} value={businessUnit._id}>
                    {businessUnit.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              fullWidth
              variant="standard"
              margin="normal"
              disabled={!filteredDepartments.length}
            >
              <InputLabel>Department</InputLabel>
              <Select
                name="department"
                value={user.department}
                onChange={handleChange}
                disabled={!filteredDepartments.length}
              >
                {filteredDepartments.map((department) => (
                  <MenuItem key={department._id} value={department._id}>
                    {department.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Employee Number"
                name="employeeNo"
                value={user.employeeNo}
                onChange={handleChange}
                variant="standard"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                name="email"
                value={user.email}
                onChange={handleChange}
                variant="standard"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                name="password"
                value={user.password}
                onChange={handleChange}
                variant="standard"
                fullWidth
              />
            </MDBox>

            {/* Hidden input for role */}
            <input type="hidden" name="role" value="Employee" />

            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                Sign Up
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Cover;
